import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Reveal from 'react-awesome-reveal';
import ReactLoading from "react-loading";
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import * as selectors from '../store/selectors';
import { fadeIn, fadeInUp, getUTCNow, getUTCDate, numberWithCommas, LoadingSkeleton, isEmpty } from '../components/utils';
import {
  isApprovedForAll,
  getNFTsOfWallet,
  setApproveForAll,
  swapNFTs
} from '../core/web3';
import Swal from 'sweetalert2';

const GlobalStyles = createGlobalStyle`
  .ico-container {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    background-size: cover;
    background-position-x: center !important;
    .ico-header {
      max-width: 900px;
      padding: 20px;
      .ico-title {
        font-size: 36px;
        font-weight: 700;
        color: #F8C42F;
      }
      .ico-desc {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 1400px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
      padding: 10px;
      .ico-header {
        padding: 20px;
        .ico-title {
          font-size: 28px;
        }
        .ico-desc {
          font-size: 18px;
        }
      }
    }
  }

  .input-token-panel {
    display: flex;
    background-color: transparent;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    width: 45%;
    .input-box {
      border: solid 1px white;
      border-radius: 8px;
      @media only screen and (max-width: 576px) {
        span {
          font-size: 15px !important;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .input-token {
    width: 50%;
    background: transparent;
    outline: none;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: normal;
    }
  }

  .email_input {
    max-width: 300px;
  }

  .presale-content {
    // max-width: 900px;
    padding: 0;
    background: rgba(70, 215, 255, 0.15);
    border-radius: 20px;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .presale-inner {
    border-radius: 12px;
    // padding: 10px 60px 40px;
    position: relative;
    background: transparent;
    min-height: 200px;
    max-width: 600px;
    min-width: 500px;
    h3 {
      line-height: 2;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 1024px) {
      padding: 60px 40px 40px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0px 10px 40px;
    }
  }

  .scrollprop {
    max-height: 600px !important;
    overflow-y: auto;
    scrollbar-width: none;
    @media screen and (max-width: 1200px) {
      overflow-y: auto;
      scrollbar-width: none;
    }
  }

  .presale-bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 76px;
  }

  .end-content {
    background: #2d81e2;
    padding: 16px;
    border-radius: 40px;
    width: 80%;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .buy_content {
    padding: 22px;
    border: solid 1.5px #5a5196;
    border-radius: 20px;
  }

  .progress-bg {
    @media only screen and (max-width: 576px) {
      width: 60%;
    }
  }

  .inverstors {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .amount_bar_text {
    display: flex;
    justify-content: space-between;
  }

  .progress {
    height: 1.5rem;
    background-color: #a9a9a9;
  }

  .progress-bar {
    background-color: #7621ff;
  }

  .MuiLoadingButton-root {
    transition: all 0.5s ease;
  }

  .MuiLoadingButton-loading {
    padding-right: 40px;
    background: linear-gradient(90deg, #aa2d78 -3.88%, #a657ae 100%);
    color: rgb(255 255 255 / 50%) !important;
    transition: all 0.5s ease;
  }
  .swal2-popup {
    border-radius: 20px;
    background: #2f2179;
    color: white;
  }
  .swal2-styled.swal2-confirm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .backdrop-loading {
  }
  
  .btn-change {
    width: 40px;
    height: 40px;
    background-color: #8b86a4 !important;
    border-radius: 50%;
    margin-bottom: 8px !important;
    color: white !important;
    &:hover {
      background-color: #8b86a4 !important;
    }
  }

  .row {
    --bs-gutter-x: 0;
  }

  .noBorder {
    text-align: center;
    color: #000;
    border: solid 2px transparent;
    padding: 5px;
    border-radius: 15px;
  }
  .withBorder {
    text-align: center;
    color: #000;
    border: solid 2px #ffffff;
    padding: 5px;
    border-radius: 15px;
  }

  .presale-input {
    align-items: end;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }
`;

const Loading = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

// function CapBackGradientSVG() {
//   const gradientTransform = `rotate(0)`;
//   return (
//     <svg style={{ height: 0 }}>
//       <defs>
//         <linearGradient id={"capBack"} gradientTransform={gradientTransform}>
//           <stop offset="0%" stopColor="rgba(236, 0, 140, 0.5)" />
//           <stop offset="90%" stopColor="rgba(252, 103, 103, 0)" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// }

// function CapGradientSVG() {
//   const gradientTransform = `rotate(0)`;
//   return (
//     <svg style={{ height: 0 }}>
//       <defs>
//         <linearGradient id={"cap"} gradientTransform={gradientTransform}>
//           <stop offset="0%" stopColor="#EC008C" />
//           <stop offset="100%" stopColor="#FC6767" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// }

// function AmountBackGradientSVG() {
//   const gradientTransform = `rotate(0)`;
//   return (
//     <svg style={{ height: 0 }}>
//       <defs>
//         <linearGradient id={"amountBack"} gradientTransform={gradientTransform}>
//           <stop offset="0%" stopColor="rgba(5, 117, 230, 0)" />
//           <stop offset="90%" stopColor="rgba(2, 242, 149, 0.61)" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// }

// function AmountGradientSVG() {
//   const gradientTransform = `rotate(0)`;
//   return (
//     <svg style={{ height: 0 }}>
//       <defs>
//         <linearGradient id={"amount"} gradientTransform={gradientTransform}>
//           <stop offset="0" stopColor="#00F260" />
//           <stop offset="23.1%" stopColor="#01D97B" />
//           <stop offset="73.02%" stopColor="#0581a0" />
//           <stop offset="99.02" stopColor="#0575E6" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// }

const AstroICO = (props) => {
  const balance = useSelector(selectors.userBalance);
  const wallet = useSelector(selectors.userWallet);
  const web3 = useSelector(selectors.web3State);
  const isMobile = useMediaQuery({ maxWidth: '768px' });
  
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [tokenIDs, setTokenIDs] = useState([]);
  const [selectedTokenIDs, setSelectedTokenIDs] = useState([]);

  const [isApproved, setIsApproved] = useState(false);

  const getInitAmount = useCallback(async () => {
    console.log('[Wallet] = ', wallet);
    if (!web3) {
      return;
    }
    setLoading(true);

    let res = await isApprovedForAll();
    if (res.success) {
      if (res.result) {
        setIsApproved(true);
      }
      else {
        setIsApproved(false);
      }
    }

    res = await getNFTsOfWallet(wallet);
    if (res.success) {
      setTokenIDs(res.nftIds);
    }
    setSelectedTokenIDs([]);
    selectedTokenIDs.slice();
    console.log(selectedTokenIDs);
    setLoading(false);
  }, [web3, wallet]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    getInitAmount();
  }, [getInitAmount, balance.usdtBalance]);

  const handleSwap = async() => {
    setPending(true);
    if (isApproved) {
      try {
        if (selectedTokenIDs.length === 0) {
          toast.error("There is no selected NFTs for swapping.")
          setPending(false);
          return;
        }
        let res = await swapNFTs(selectedTokenIDs);
        if (res.success) {
          getInitAmount();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Swaped successfully.'
          })
        } else {
          toast.error("Transcation has been failed. " + res.result);
        }
      } catch (error) {
        toast.error("Transcation has been failed. " + error);
      }
    }
    else {
      try {
        let res = await setApproveForAll();
        if (res.success) {
          getInitAmount();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Approved successfully.'
          });
        } else {
          toast.error("Transcation has been failed. " + res.result);
        }
      } catch (error) {
        toast.error("Transcation has been failed. " + error);
      }
    }
    setPending(false);
  }

  const IsSelected = (tokenId) => {
    var a = 0;
    for (a = 0; a < selectedTokenIDs.length; a++) {
      if (selectedTokenIDs[a] === tokenId) {
        return true;
      }
    }
    return false;
  };

  const removeItemFromArray = (oldlist, tokenId) => {
    var list = oldlist;
    var i = 0;
    for (i = 0; i < list.length; i++) {
      if (list[i] === tokenId) {
        list[i] = list[list.length - 1];
        list.pop();
        break;
      }
    }
    return list;
  };

  const imageClick = async(tokenId, index) => {
    if (await IsSelected(tokenId)) {
      let newList = removeItemFromArray(selectedTokenIDs.slice(), tokenId);
      setSelectedTokenIDs(newList);
    } else {
      var newList = selectedTokenIDs.slice();
      newList.push(tokenId);
      setSelectedTokenIDs(newList);
    }
  }

  return (
    <div className='page-container text-center ico-container'>
      <GlobalStyles />
      <div className='ico-header'>
        <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
          <p className='ico-title'>Welcome to VMN Swap</p>
        </Reveal>
        <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
          <p className="ico-desc">
          </p>
        </Reveal>
        <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
          <p className="ico-desc">
          </p>
        </Reveal>
      </div>
      {loading ? (
        <div className='backdrop-loading'>
          <Loading className='loading'>
            <ReactLoading type={'spinningBubbles'} color="#fff" />
          </Loading>
        </div>
      ) : (
        <>
          <Reveal className='onStep' keyframes={fadeIn} delay={800} duration={800} triggerOnce>
            <section className='presale-content'>
              <div className='presale-inner pt-3 pb-4'>
                <div className="scrollprop">
                  <div className="row justify-content-center">
                    {tokenIDs && tokenIDs.map((tokenId, idx) => {
                      const isSelected = IsSelected(tokenId);
                      let image = "https://ipfs.io/ipfs/QmVzewj9ggDTQJhkTvKtVytTpCskfjrMTZjaGpP67d1GBQ/" + tokenId + ".png";
                      return (
                        <div
                          className="col-5 col-md-5 col-lg-5 col-xl-3 item"
                          style={{
                            marginLeft: 1,
                            marginRight: 1,
                            marginTop: 30,
                          }}
                          onClick={() => imageClick(tokenId, idx)}
                        >
                          <img
                            className={isSelected? "withBorder" : "noBorder"}
                            src={image}
                            alt=""
                            style={{ width: "100%" }}
                          />
                          <div
                            style={{
                              color: "white",
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {tokenId}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </section>
          </Reveal>
          <Reveal className='main mt-3 onStep' keyframes={fadeIn} delay={800} duration={800} triggerOnce>
            <div className="row justify-center">
              <div className='col-md-12 mt-3'>
                <LoadingButton
                  onClick={handleSwap}
                  endIcon={<></>}
                  loading={pending}
                  loadingPosition="end"
                  variant="contained"
                  className="btn-main btn3 m-auto fs-20"
                >
                  {isApproved? "Swap" : "Approve"}
                </LoadingButton>
              </div>
            </div>
          </Reveal>
        </>
      )}
    </div >
  );
};

export default AstroICO;    