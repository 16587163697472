var oldNFT_abi = require("./ABI/oldNFT.json");
var newNFT_abi = require("./ABI/newNFT.json");
var swapNFT_abi = require("./ABI/swapNFT.json");

export const config = {
    chainId: 43113, //Fuji testnet : 43113, mainnet : 43114.  bsctestnet : 97, Rikeby: 4
    mainNetUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    // mainNetUrl: 'https://bsc-dataseed1.ninicoin.io',
    // mainNetUrl: 'https://rinkeby.infura.io/v3/',
    

    OldNFTAddress: '0x427Eeb0731C661af0CCeb70677D3f6c421C756Bf',
    OldNFTAbi : oldNFT_abi,
    NewNFTAddress: '0x5CC669278D13A8d68AF0160ad0419a8a68ff3f65',    
    NewNFTAbi: newNFT_abi,
    SwapNFTAddress: '0x075771f8916356237902C0A3806B040B7eb607Ac',
    SwapNFTAbi: swapNFT_abi,
    INFURA_ID: 'e6943dcb5b0f495eb96a1c34e0d1493e'
}